import React, { useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import ImgTag from "../../components/Elements/ImgTag";
import {
  client1SourceURL,
  client2SourceURL,
  client3pngSourceURL,
  client3SourceURL,
  client4pngSourceURL,
  client4SourceURL,
  contactImgSourceURL,
  happyClientSourceURL,
  icon1SourceURL,
  icon2SourceURL,
  icon3SourceURL,
  icon4SourceURL,
  icon5SourceURL,
  icon6SourceURL,
  icon7SourceURL,
  icon8SourceURL,
  serviceSourceURL,
  sliderPosterImgSourceURL,
  theSamSoftIconSourceURL,
} from "../../assets/js/Constants/localImageURL";
import LangBg from "../../components/Svg/LangBg";
import { Link } from "react-router-dom";
import { t } from "i18next";
import SearchBar from "../../components/SearchBar/SearchBar";
import CountUp from "react-countup";

function HomePage() {
  const [startCounting, setStartCounting] = useState(false);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCounting(true);
          observer.unobserve(entry.target); // Stop observing once it's in view
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the element is visible
      }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);
  return (
    <>
      <section id="hero_sec" className="hero_sec">
        <div className="hero_body">
          <div className="container">
            <div className="row sec_row">
              <div className="col-lg-6 col-md-12 content_col">
                <div className="content">
                  <div className="sec_title">
                    <h1 className="title">
                      {t(
                        "Stunning_Websites_Website_Development_Digital_Marketing_Innovative_and_Captivating_Solutions"
                      )}
                    </h1>
                  </div>
                  <p className="text">
                    {t(
                      "Transform_your_digital_presence__meeting_all_your_technical_needs"
                    )}
                  </p>
                  <SearchBar />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 img_col">
                <div className="img_box">
                  <ImgTag sourceURL={sliderPosterImgSourceURL} altText="website development and digital marketing" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Hero Section End */}
      <section id="services_sec" className="services_sec">
        <div className="services">
          <div className="container">
            <div className="sec_title">
              <h2 className="sam_title text-center">{t("Our_Services")}</h2>
            </div>
            <div className="row sec_row">
              <div className="col-lg-4 col-md-6 col-sm-12 service_col">
                <div className="service_box">
                  <h3 className="service_title">{t("Website_Development")}</h3>
                  <p className="text">
                    {t("We_create_visually_stunning__captivates_your_audience")}
                  </p>
                </div>
                <div className="service_box">
                  <h3 className="service_title">{t("Graphic_Designing")}</h3>
                  <p className="text">
                    {t(
                      "We_specialize_in_graphic_designing_and_design_and_leave_a_lasting_impression_on_your_audience"
                    )}
                  </p>
                </div>
                <div className="service_box">
                  <h3 className="service_title">{t("Digital_Marketing")}</h3>
                  <p className="text">
                    {t(
                      "Our_tailored_digital_marketing_strategies__business_objectives"
                    )}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 img_col">
                <div className="center_box">
                  <div className="img">
                    <ImgTag sourceURL={serviceSourceURL} altText="services" />
                  </div>
                  <div className="service_btn">
                    <Link to="services">TheSamSoft {t("Services")}</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 service_col">
                <div className="service_box">
                  <h3 className="service_title">{t("App_Development")}</h3>
                  <p className="text">
                    {t(
                      "Our_custom_app_development_delivers_innovative__business_goals"
                    )}
                  </p>
                </div>
                <div className="service_box">
                  <h3 className="service_title">{t("UI_UX_Designer")}</h3>
                  <p className="text">
                    {t(
                      "We_craft_intuitive_and_engaging_interfaces__digital_platforms"
                    )}
                  </p>
                </div>
                <div className="service_box">
                  <h3 className="service_title">{t("Additional_Services")}</h3>
                  <p className="text">
                    {t(
                      "We_offer_crucial_support_with_technical_assistance_operations_and_success"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Services Section End */}
      <section id="feature_sec" className="feature_sec">
        <div className="sec_body">
          <div className="container">
            <div className="sec_title">
              <h2 className="sam_title text-center">{t("Website_Features")}</h2>
            </div>
            <div className="row sec_row">
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 feature_col">
                <div className="feature_card">
                  <div className="feature_img">
                    <ImgTag sourceURL={icon1SourceURL} altText="budget-friendly" />
                  </div>
                  <div className="content text-center">
                    <h3 className="title">{t("Budget-Friendly_Theme")}</h3>
                    <p className="text">
                      {t(
                        "Rest_easy_Our_themes_are_designed_to_be_budget_friendly__with_affordability"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 feature_col">
                <div className="feature_card">
                  <div className="feature_img">
                    <ImgTag sourceURL={icon2SourceURL} altText="code" />
                  </div>
                  <div className="content text-center">
                    <h3 className="title">{t("Robust_Code_Standards")}</h3>
                    <p className="text">
                      {t("Our_themes_use_clean__how_you_envision_it")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 feature_col">
                <div className="feature_card">
                  <div className="feature_img">
                    <ImgTag sourceURL={icon3SourceURL} altText="seo" />
                  </div>
                  <div className="content text-center">
                    <h3 className="title">{t("Search_Engine_Optimised")}</h3>
                    <p className="text">
                      {t(
                        "Our_advanced_coding_boosts_SEO_performance_gains_the_visibility_it_deserves"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 feature_col">
                <div className="feature_card">
                  <div className="feature_img">
                    <ImgTag sourceURL={icon4SourceURL} altText="wordpress" />
                  </div>
                  <div className="content text-center">
                    <h3 className="title">{t("Drag_and_Drop_Editor")}</h3>
                    <p className="text">
                      {t("Easily_build_your_website_with_our__without_coding")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 feature_col">
                <div className="feature_card">
                  <div className="feature_img">
                    <ImgTag sourceURL={icon5SourceURL} altText="customer support" />
                  </div>
                  <div className="content text-center">
                    <h3 className="title">{t("Personalized_Support")}</h3>
                    <p className="text">
                      {t(
                        "Our_detailed_documentation_has_you_covered__our_priority"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 feature_col">
                <div className="feature_card">
                  <div className="feature_img">
                    <ImgTag sourceURL={icon6SourceURL} altText="mobile application development" />
                  </div>
                  <div className="content text-center">
                    <h3 className="title">{t("Mobile_Optimized_Themes")}</h3>
                    <p className="text">
                      {t(
                        "Our_mobile_responsive_themes_guarantee__smartphones_to_desktops"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 feature_col">
                <div className="feature_card">
                  <div className="feature_img">
                    <ImgTag sourceURL={icon7SourceURL} altText="web development" />
                  </div>
                  <div className="content text-center">
                    <h3 className="title">{t("Simple_Customization")}</h3>
                    <p className="text">
                      {t(
                        "Easily_tailor_your_website_with_our_versatile_customizer__for_your_creativity"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 feature_col">
                <div className="feature_card">
                  <div className="feature_img">
                    <ImgTag sourceURL={icon8SourceURL} altText="website theme design" />
                  </div>
                  <div className="content text-center">
                    <h3 className="title">{t("User_Friendly_Theme")}</h3>
                    <p className="text">
                      {t(
                        "Our_flexible_Admin_Dashboard_lets_you_manage__intuitive_interface"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature Section End */}
      <section id="overview_sec" className="overview_sec">
        <div className="container">
          <div className="overview_body">
            <div className="row overview_row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 overview_left_col">
                <div className="overview_content mb-5">
                  <div className="sec_title">
                    <h2 className="sam_title mb-0">
                      {t("Discover_the_Magic_of_Website")}
                    </h2>
                  </div>
                  <p className="text">
                    {t("Ready_to_build_your_website__and_lightweight_coding")}
                  </p>
                </div>
                <div className="row counter_row" ref={counterRef}>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 counter_col ps-0">
                    <div className="counter_card">
                      <h3 className="number">
                        {startCounting && (
                          <CountUp
                            className="number"
                            start={0}
                            end={500}
                            duration={5}
                          />
                        )}
                        +
                      </h3>
                      <h4 className="title">{t("Premium_Theme")}</h4>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 counter_col pe-0">
                    <div className="counter_card">
                      <h3 className="number">
                        {startCounting && (
                          <CountUp
                            className="number"
                            start={0}
                            end={175}
                            duration={5}
                          />
                        )}
                        +
                      </h3>
                      <h4 className="title">{t("Happy_Customer")}</h4>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 counter_col ps-0">
                    <div className="counter_card">
                      <h3 className="number">
                        {startCounting && (
                          <CountUp
                            className="number"
                            start={0}
                            end={400}
                            duration={5}
                          />
                        )}
                        +
                      </h3>
                      <h4 className="title">{t("Support_Request")}</h4>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 counter_col pe-0">
                    <div className="counter_card">
                      <h3 className="number">
                        {startCounting && (
                          <CountUp
                            className="number"
                            start={0}
                            end={100}
                            duration={5}
                          />
                        )}
                        %
                      </h3>
                      <h4 className="title">{t("After_Sale_Support")}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 overview_right_col">
                <ul>
                  <li>
                    <div className="overview_logo">
                      <ImgTag sourceURL={theSamSoftIconSourceURL} altText="TheSamsoft | Website Development | Digital Marketing" />
                    </div>
                  </li>
                  <li>
                    <div className="overview_img">
                      <ImgTag sourceURL={happyClientSourceURL} altText="wordpress" />
                    </div>
                  </li>
                  <li className="overview_other_content">
                    <div className="content">
                      <div className="content_box">
                        <h5 className="title">
                          {t(
                            "WORDPRESS_THEMES_WITH_SEAMLESS_MOBILE_RESPONSIVENESS"
                          )}
                        </h5>
                        <h6 className="text">
                          {t(
                            "Build_Your_Website_with_Top_Rated_WordPress_Themes"
                          )}
                        </h6>
                        <div className="more_btn">
                          <Link to="contact-us" className="btn">
                            {t("Contact_Us")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Overview Section End */}
      <section id="contact_sec" className="contact_sec">
        <div className="container">
          <div className="row contact_row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 img_col">
              <div className="img">
                <ImgTag sourceURL={contactImgSourceURL} altText="responsive web design" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 content_col">
              <div className="contact_content">
                <div className="sec_title">
                  <h2 className="sam_title mb-4">
                    {t(
                      "Unlock_Endless_Customization_Possibilities_with_Thesamsoft"
                    )}
                  </h2>
                </div>
                <p className="text">
                  {t("With_Thesamsoft_innovate_without_constraints")}
                </p>
                <div className="contact_btn">
                  <Link to="contact-us" className="btn">
                    {t("Contact_Us")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Home Conatact Section End */}
      <section id="testimonial_sec" className="testimonial_sec pb-0">
        <div className="testimonials">
          <div className="container">
            <div className="sec_title">
              <h2 className="sam_title text-center">{t("Customer_review")}</h2>
            </div>
            <div className="testimonial_slider">
              <OwlCarousel
                className="owl-carousel owl-theme"
                nav={false}
                loop={true}
                margin={10}
                dots={false}
                autoplay={true}
                autoplayTimeout={3000}
                // lazyLoad={true}
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 2,
                  },
                  1000: {
                    items: 3,
                  },
                }}
              >
                <div className="item">
                  <div className="client_say">
                    <div className="client_info d-flex">
                      <div className="client_img">
                        <ImgTag
                          className="rounded-circle"
                          sourceURL={client3pngSourceURL}
                          altText="Sophia Harper Profile Image"
                        />
                      </div>
                      <div className="client_name">
                        <h3 className="name">{t("Sophia_Harper")}</h3>
                        <ul className="stars d-flex ps-0">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </ul>
                      </div>
                    </div>
                    <div className="text">
                      <p>
                        {t(
                          "TheSamSofts_web_development_services_are_top_notch_website_for_our_business"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="client_say">
                    <div className="client_info d-flex">
                      <div className="client_img">
                        <ImgTag
                          className="rounded-circle"
                          sourceURL={client2SourceURL}
                          altText="Liam Blake Profile Image"
                        />
                      </div>
                      <div className="client_name">
                        <h3 className="name">{t("Liam_Blake")}</h3>
                        <ul className="stars d-flex ps-0">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </ul>
                      </div>
                    </div>
                    <div className="text">
                      <p>
                        {t(
                          "Impressed_by_TheSamSofts_web_design_expertise_They_delivered_a_visually_stunning_site_that_perfectly_represents_our_brand"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="client_say">
                    <div className="client_info d-flex">
                      <div className="client_img">
                        <ImgTag
                          className="rounded-circle"
                          sourceURL={client3SourceURL}
                          altText="Ella Lane Profile Image"
                        />
                      </div>
                      <div className="client_name">
                        <h3 className="name">{t("Ella_Lane")}</h3>
                        <ul className="stars d-flex ps-0">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </ul>
                      </div>
                    </div>
                    <div className="text">
                      <p>
                        {t("Amazing_experience_with_TheSamSoft__Five_stars")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="client_say">
                    <div className="client_info d-flex">
                      <div className="client_img">
                        <ImgTag
                          className="rounded-circle"
                          sourceURL={client4SourceURL}
                          altText="Lucas Reed Profile Image"
                        />
                      </div>
                      <div className="client_name">
                        <h3 className="name">{t("Lucas_Reed")}</h3>
                        <ul className="stars d-flex ps-0">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </ul>
                      </div>
                    </div>
                    <div className="text">
                      <p>
                        {t(
                          "TheSamSoft_provided_everything_we_needed_Highly_satisfied"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="client_say">
                    <div className="client_info d-flex">
                      <div className="client_img">
                        <ImgTag
                          className="rounded-circle"
                          sourceURL={client1SourceURL}
                          altText="Ava Ellis Profile Image"
                        />
                      </div>
                      <div className="client_name">
                        <h3 className="name">{t("Ava_Ellis")}</h3>
                        <ul className="stars d-flex ps-0">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </ul>
                      </div>
                    </div>
                    <div className="text">
                      <p>
                        {t(
                          "We_were_thrilled_with_TheSamSoft_customization_capabilities__smooth_and_effective"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="client_say">
                    <div className="client_info d-flex">
                      <div className="client_img">
                        <ImgTag
                          className="rounded-circle"
                          sourceURL={client4pngSourceURL}
                          altText="Isabella Harper Profile Image"
                        />
                      </div>
                      <div className="client_name">
                        <h3 className="name">{t("Isabella_Harper")}</h3>
                        <ul className="stars d-flex ps-0">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </ul>
                      </div>
                    </div>
                    <div className="text">
                      <p>
                        {t(
                          "TheSamSoft_exceeded_our_expectations__works_perfectly"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/* Testimonial Section End */}
      <section id="pro_lang_sec" className="pro_lang_sec">
        <div className="pro_langs">
          <div className="img">
            <LangBg />
          </div>
        </div>
      </section>
      {/* Language Section End */}
    </>
  );
}

export default HomePage;
